@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GothamBookRnd';
  src: url('assets/fonts/GothamRoundedBook.ttf');
}

@font-face {
  font-family: 'GothamBookRndBold';
  src: url('assets/fonts/GothamRoundedBold.ttf');
}

html,
body,
#root {
  @apply w-full h-full overflow-hidden;
}

#root {
  @apply text-[100%];
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.wrapper-loader {
  div {
    @apply w-32 h-32;
  }
}

.Toastify__toast-container {
  @apply top-0 left-1/2 w-[calc(100%-(2rem*2))] max-w-xl mt-3 -translate-x-1/2;
}

.Toastify__toast {
  @apply rounded-md;
}

.qr-code-scanner {
  @apply h-full;

  section {
    @apply h-full;
  }
}

/* override flag icon style for baseweb phoneinput */
span[data-iso] {
  @apply text-xl leading-none;
}

.swiper-slide {
  @apply w-auto h-auto !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
